.avatar {
  width: 80px;
  height: 80px;
  background: #e6e6e6;
  color: #626262;
  margin: auto;
  font-weight: normal;
  border-radius: 50%;
}
.avatar .img {
  width: 50%;
  height: 100%;
}
.card__text {
  font-size: 1.1rem;
  font-weight: 600;
  color: rgb(231, 94, 94);
}
.dash__list{
    border-radius: 5px;
}
.dash__list span{
font-size: 0.8rem;
margin-left: 5px;

}