/* 모달창을 화면 중앙. 최상단에 노출 */

.modal__container {
    /* 모달창 크기 */
    width: 400px;
    height: 300px;
  
    /* 최상단 위치 */
    z-index: 999;
    
    /* 중앙 배치 */
    /* top, bottom, left, right 는 브라우저 기준으로 작동한다. */
    /* translate는 본인의 크기 기준으로 작동한다. */
    position: absolute;
    top: 20%;
    right: -3%;
    transform: translate(-50%);
    transition: .5s;
    /* 모달창 디자인 */
    background-color: #fff !important;
    border-radius: 8px;
    /* border: 1px solid rgba(0,0,0,0.1); */
    box-shadow: 0px 0px 12px 1px rgba(0,0,0,0.1) !important;
  }
  
  /* 모달창 내부 X버튼 */
  .modal__close {
    position: absolute;
    right: 10px;
    top: 10px;
  }